/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('[data-toggle="tooltip"]').tooltip();
      },
      finalize: function() {

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // Fade in load all animation
        if ($('.mobile-logo').css('display') === 'none'){
          $('.main').imagesLoaded({ background: '.home-slide__wrapper__ul__item__img' }).done( function( instance ) {
              $('.preload').fadeOut(500);
              $('.sidebar').removeClass('is-preload');
          });
        } else {
          $('.preload').fadeOut(500);
          $('.sidebar').removeClass('is-preload');
        }
        // open and close the profile section
        $('.intro__more').click(function(e){
          e.preventDefault();
          $('.backdrop').fadeIn();
          $('.profile').delay(700).fadeIn();
        });
        $('.profile__close').click(function(e){
          e.preventDefault();
          $('.backdrop').fadeOut();
          $('.profile').fadeOut();
        });

        // add is-next is-prev class to slide
        $('.showcase__slide__item:eq( 1 )').addClass('is-next');
        $('.showcase__slide__item:eq( 2 )').addClass('is-prev');

        // click the slide jumper
        $('.showcase__jumper__link').click(function(e){
          animateLines1();
          e.preventDefault();
          $('.showcase__slide__item.is-prev').removeClass('is-prev');
          $('.showcase__slide__item.active').addClass('is-prev').removeClass('active');
          $('.showcase__slide__item.is-next').addClass('active').removeClass('is-next');
          if ($('.showcase__slide__item.active').next().length){
            $('.showcase__slide__item.active').next().addClass('is-next');
          } else {
            $('.showcase__slide__item:first').addClass('is-next');
          }

          var active = $( ".active" );
          var activeSlide = $('.showcase__slide__item').index(active);
          $('.showcase__jumper__current').text(activeSlide + 1);
        });
        // slide swipe events
        var myElement = document.getElementById('myElement');
        var mc = new Hammer(myElement);

        mc.on("swiperight", function(ev) {
          animateLines1();
          $('.showcase__slide__item.is-prev').removeClass('is-prev');
          $('.showcase__slide__item.active').addClass('is-prev').removeClass('active');
          $('.showcase__slide__item.is-next').addClass('active').removeClass('is-next');
          if ($('.showcase__slide__item.active').next().length){
            $('.showcase__slide__item.active').next().addClass('is-next');
          } else {
            $('.showcase__slide__item:first').addClass('is-next');
          }

          var active = $( ".active" );
          var activeSlide = $('.showcase__slide__item').index(active);
          $('.showcase__jumper__current').text(activeSlide + 1);
        });

        mc.on("swipeleft", function(ev) {
          animateLines1();
          $('.showcase__slide__item.is-next').removeClass('is-next');
          $('.showcase__slide__item.active').addClass('is-next').removeClass('active');
          $('.showcase__slide__item.is-prev').addClass('active').removeClass('is-prev');
          if ($('.showcase__slide__item.active').prev().length){
            $('.showcase__slide__item.active').prev().addClass('is-prev');
          } else {
            $('.showcase__slide__item:last').addClass('is-prev');
          }

          var active = $( ".active" );
          var activeSlide = $('.showcase__slide__item').index(active);
          $('.showcase__jumper__current').text(activeSlide + 1);
        });

        // Velocity.js instead of CSS for performance
        $.Velocity.RegisterEffect("transition.stripeIn", {
          defaultDuration: 600,
          calls: [
              [ { opacity: [0, 1], translateY: [ 50, -100 ], translateX: [ 50, -100 ] } ]
          ]
        });
        function animateLines1() {
          if($('.logo').css('margin-top') > '20px' ){
            // Reset Any OnGoing Animation
            $(".showcase__stripe line").velocity("stop");
            // Real Animation
            $(".showcase__stripe line").velocity("transition.stripeIn",{
               easing: "easeOutSine",
               stagger: 100,
            });
          }
        }
        animateLines1();
      }
    },
    'single': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // init slide
        $('#carousel').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          itemWidth: 100,
          itemMargin: 5,
          asNavFor: '#slider'
        });
        
        $('#slider').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          sync: "#carousel"
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
